<template>
  <div v-if="transaction" class="worker-details">
    <SidebarHeader
        :handle-sidebar="handleSidebar"
        :is-back-button-active="false"
        :is-menu-enabled="$auth.isOwner()"
        :menu-items="getMenuOptions()"
        :title="$t('admin.transactions.detail.title')"
    />
    <div class="worker-profile basic-padding scrollable-sidebar">
      <SideTitleSidebarComponent :title="$t('admin.transactions.detail.first-section')"/>
      <SidebarEntry v-if="transaction.transactionId" :title="$t('admin.transactions.detail.transaction_id')"
                    :value="transaction.transactionId"/>
      <SidebarEntry v-if="$auth.isOwner() && transaction.transactionIdExt" :title="$t('admin.transactions.detail.transaction_id')"
                    :value="transaction.transactionIdExt.toString()"/>
      <SidebarEntry v-if="transaction.refid" :title="$t('admin.transactions.detail.refid')"
                    :value="transaction.refid"/>
      <SidebarEntry :title="$t('admin.transactions.detail.creation-date')"
                    :value="getDate(transaction.creationDate)"/>
      <SidebarEntry :title="$t('admin.transactions.detail.update-date')"
                    :value="getDate(transaction.updateDate)"/>
      <SidebarEntry :title="$t('admin.transactions.detail.type')"
                    :value="getPaymentType(transaction.paymentType)"/>
      <SidebarEntry :title="$t('admin.transactions.detail.amount')"
                    :value="transaction.amount ? getAmount(transaction.amount) : getAmount(transaction.totalAmount)"/>
      <SidebarEntryStatus v-if="transaction.status"
                          :action-text="getCorrectStatusName(transaction)"
                          :status="getPaymentStatus(transaction.status, transaction.approvalStatus, transaction.isCompanyTwoLevelApproval )"
                          :title="$t('admin.transactions.detail.status')"/>
      <SidebarEntryStatus
          v-if="transaction.isActionRequired"
          :action-text="getActionText(transaction)"
          :status="getPaymentStatus(transaction.status)"
          :title="$t('admin.transactions.detail.action')"
      />
      <BlankLine v-if="!transaction.isActionRequired" :number-of-rows="1"/>
      <SidebarEntry
          v-if="shouldShowReason(transaction.reasonNote)"
          :title="$t('admin.transactions.detail.reason-update')"
          :value="transaction.reasonNote"
      />
      <SidebarEntry :title="$t('admin.transactions.detail.description')" :value="transaction.description"/>
      <SidebarEntry :title="$t('admin.transactions.retry')" :value="getRetries(transaction.retries)"/>
    </div>
    <div class="worker-profile basic-padding">
      <SideTitleSidebarComponent :title="$t('admin.transactions.detail.second-section')"/>
      <SidebarEntry :title="$t('admin.transactions.detail.uid')" :value="transaction.uid"/>
      <SidebarEntry :title="$t('admin.transactions.detail.company')" :value="transaction.accountHolderName"/>
      <SidebarEntry :is-clickable="true"
                    :on-click="handleWorkerSidebar"
                    :title="$t('admin.transactions.detail.worker')"
                    :value="transaction.counterpartyName"
      />
      <SidebarEntry :title="$t('admin.transactions.detail.iban')" :value="transaction.counterpartyIban"/>
      <SidebarEntry :title="$t('admin.transactions.transaction-detail.threshold')"
                    :value="transaction.threshold? transaction.threshold : 'N/A' "/>
      <SidebarEntry :is-code="true" :title="$t('admin.transactions.detail.note')"
                    :value="transaction.note ? transaction.note : 'N/A' "/>
    </div>
    <div v-if="transaction.paycAdjustments && transaction.paycAdjustments.length > 0"
         class="worker-profile  basic-padding">
      <h4>{{ $t('admin.transactions.detail.third-section') }}</h4>
      <br/>
      <div v-for="adj in transaction.paycAdjustments" :key="adj.id">
        <b-row>
          <b-col class="linked-gig-date gig-shift-date-container">
            {{
              adj.updateDate ? getDateTextName(adj.updateDate) : getDateTextName(getDate(adj.updateDate))
            }}
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col class="basic-info">{{ transaction.employerName }}</b-col>
          <b-col class="basic-info text-aligned-to-right">{{ getAmount(adj.adjustedAmount) }}</b-col>
        </b-row>
      </div>
    </div>
    <b-row v-if="isNeedToApproval(actualStatus) && isUserEnabledToPerformOperations()">
      <ButtonContainer is-wrapped>
        <template v-slot:mainButton>
          <TransactionButton
              v-b-toggle:sidebar-refuse
              :button-text="$t('admin.transactions.detail.approve-payment')"
              :on-click="approvePayment"
              :payment-type="transaction.paymentType"
              :transaction-id="transaction.id"
          />
        </template>
        <template v-slot:secondaryButton>
          <SecondaryButton v-b-toggle:sidebar-refuse
                           :button-text="$t('admin.transactions.detail.refuse-payment')"
                           :on-click="refusePayment"
          />
        </template>
      </ButtonContainer>
    </b-row>
    <b-sidebar id="sidebar-refuse" backdrop no-header right shadow width="500px">
      <ManageTransaction :is-approve="isApproving"
                         :is-approve-loading="isLoading"
                         :is-payout="isPayout(transaction.paymentType)"
                         :is-pis="isPis(transaction.paymentType)"
                         :is-wal="false"
                         :is-walc="true"
                         :payment-type="transaction.paymentType"
                         :pid="transaction.id"
                         :table="table"/>
    </b-sidebar>
    <b-sidebar id="update-tx" backdrop no-header right shadow width="500px">
      <TransactionUpdate
          :key="`${transaction.id}_${transaction.uid}_${Math.random()}`"
          :paymentType="transaction.paymentType"
          :table-ref="tableRef"
          :transaction="transaction"
      />
    </b-sidebar>
    <b-sidebar v-if="$auth.isOwner() && (transaction.transactionId || transaction.id)" id="history" backdrop no-header
               right shadow
               width="500px">
      <TransactionHistory
          v-if="$auth.isOwner()"
          :key="`${transaction.id}_${transaction.uid}_${Math.random()}`"
          :handle-sidebar="handleHistorySidebar"
          :is-payout="isAnyKindOfPayout()"
          :is-pis="isPis(transaction.paymentType)"
          :is-wal="false"
          :is-walc="isWalc(transaction.paymentType)"
          :transaction-id="transaction.id"
      />
    </b-sidebar>
  </div>
</template>

<script>

import ManageTransaction from '@/views/sidebar/TransactionDetail/ManageTransaction.vue';
import FormatUtils from '@/utils/FormatUtils';
import ApiUtils from '@/utils/ApiUtils';
import ToasterUtils from '@/utils/ToasterUtils';
import TransactionStatusUtils from '@/utils/TransactionStatusUtils';
import ButtonContainer from '@/components/Buttons/ButtonContainer';
import TransactionButton from '@/components/Buttons/TransactionButton';
import SecondaryButton from '@/components/Buttons/SecondaryButton';
import PaymentType from '@/utils/PaymentType';
import SidebarHeader from '@/components/Layout/Header/SidebarHeader';
import SideTitleSidebarComponent from '@/views/sidebar/basic/SideTitleSidebarComponent';
import SidebarEntry from '@/views/sidebar/basic/SidebarEntry';
import SidebarEntryStatus from '@/views/sidebar/basic/SidebarEntryStatus';
import TransactionUpdate from '@/components/Transaction/TransactionUpdate/TransactionUpdate';
import PaymentStatus, {getPaymentStatus} from '@/utils/PaymentStatus';
import FilterConstant from '@/components/Filter/FilterConstant';
import TransactionHistory from '@/components/Transaction/TransactionHistory/TransactionHistory.vue';
import StatusUtils from '@/utils/StatusUtils.vue';
import BlankLine from "@/components/Blank/BlankLine.vue";

const SUCCESS = 200;
export default {
    name: 'TransactionDetailSidebar',
    components: {
        BlankLine,
        TransactionHistory,
        TransactionUpdate,
        SidebarEntryStatus,
        SidebarEntry,
        SideTitleSidebarComponent,
        SidebarHeader,
        SecondaryButton,
        TransactionButton,
        ButtonContainer,
        ManageTransaction,
    },
    mixins: [FormatUtils, ApiUtils, TransactionStatusUtils, ToasterUtils, FilterConstant, StatusUtils],
    props: {
        transaction: {
            type: Object,
            default: () => ({
                transactionId: -1,
                refid: -1,
                amount: -1,
                status: '',
                creationDate: new Date(),
                updateDate: new Date(),
            }),
        },
        table: {
            type: Object,
            default: () => {
            },
        },
        tableRef: {
            type: Object,
            required: false,
            default: () => {
            },
        },
        handleSidebar : {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            isApproving: true,
            refusalReason: '',
            responseStatus: '',
            sidebarEvent: 'bv::toggle::collapse',
        };
    },
    computed: {
        actualStatus() {
            return this.transaction.status || this.responseStatus;
        },
    },
    async mounted() {
        this.$root.$on(this.TRANSACTION_APPROVE_REFUSE_RESPONSE, responseStatus => {
            this.responseStatus = responseStatus;
        });
    },
    methods: {
        getPaymentStatus,
        isNeedToApproval(status) {
            return status === PaymentStatus.TOCONFIRM.symbol;
        },
        isUserEnabledToPerformOperations() {
            const {approver, approvedBy, intermediateStatus, isTwoLevelApprovalFinal} = this.transaction;
            if (isTwoLevelApprovalFinal) {
                return false;
            }
            // If none of these attributes is set, this is an edge case, and we need to default to Payday
            if (approvedBy === null && approver === null && intermediateStatus === null) {
                return this.$auth.isOwner();
            }
            if (approver === 'ROLE_OWNER') {
                return this.$auth.isOwner();
            } else {
                return this.$auth.isAdmin();
            }
        },
        shouldShowReason(reasonNote) {
            return !!reasonNote;
        },
        getCorrectAmount(transactions) {
            const isPis = this.isPis(transactions.paymentType);
            const isWal = this.isWal(transactions.paymentType);
            const isWalc = this.isWalc(transactions.paymentType);
            if (isPis || isWal) {
                return transactions.amount;
            }
            if (isWalc) {
                return transactions.totalAmount;
            }
            return 0;
        },
        isPis(paymentType) {
            return paymentType === PaymentType.PIS.symbol;
        },
        isWal(paymentType) {
            return paymentType === PaymentType.WAL.symbol;
        },
        isPayout(paymentType) {
            return paymentType === PaymentType.PAYOUT.symbol
                || paymentType === PaymentType.PAYOUTAUTO.symbol;
        },
        isPayoutFee(paymentType) {
            return paymentType === PaymentType.PAYOUTFEE.symbol;
        },
        isPayoutSet(paymentType) {
            return paymentType === PaymentType.PAYOUTSET.symbol;
        },
        isAutoPayout(paymentType) {
            return paymentType === PaymentType.PAYOUTAUTO.symbol;
        },
        isWalc(paymentType) {
            return paymentType === PaymentType.WALCADJ.symbol
                || paymentType === PaymentType.WALC.symbol;
        },
        isAnyKindOfPayout() {
            return this.isPayout(this.transaction.paymentType)
                || this.isPayoutFee(this.transaction.paymentType)
                || this.isPayoutSet(this.transaction.paymentType)
                || this.isPis(this.transaction.paymentType);
        },
        isUpdatable(status) {
            return status === PaymentStatus.REFUSED.symbol
                || status === PaymentStatus.REJECTED.symbol
                || status === PaymentStatus.SCHEDULED.symbol;
        },
        getDate(date) {
            return `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`;
        },
        getDateTextName(date) {
            const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December',
            ];
            return `${new Date(date).getDate()} ${monthNames[new Date(date).getMonth()]}`;
        },
        async approvePayment(paymentType, pid) {
            this.isLoading = true;
            this.isApproving = true;
            await this.setHeader();
            const isPis = this.isPis(paymentType);
            const isWalc = this.isWalc(paymentType);
            const isWalcPayout = this.isPayout(paymentType);
            try {
                let response;
                switch (true) {
                case (isPis):
                    response = this.$api.approvePisPayment(pid);
                    break;
                case (isWalc):
                    response = this.$api.approveWalcPayment(pid);
                    break;
                case (isWalcPayout):
                    response = this.$api.approveWalcoutTransaction(pid);
                    break;
                default:
                    response = this.$api.approveWalcoutTransaction(pid);
                    break;
                }
                response.then(res => {
                    this.responseStatus = res.data.status;
                    this.transaction.status = res.data.status;
                    this.isLoading = false;
                    this.makeToast(res.status === SUCCESS, `Payment ${this.transaction.id} Approved`);
                    setTimeout(()=>{
                        this.table.refresh();
                        this.handleSidebar();
                    }, 700);
                }).catch(e => {
                    this.responseStatus = e;
                    this.makeToast(false, `Payment ${this.transaction.id} Refused`);
                });
            } catch (e) {
                this.responseStatus = e;
                this.makeToast(false, `Payment ${this.transaction.id} Refused`);
            }
            this.isLoading = false;
        },
        async refusePayment() {
            this.isApproving = false;
            this.$root.$emit('bv::toggle::modal', 'sidebar-refuse');
        },
        getRetries(retry) {
            if (retry === null || retry === undefined) {
                return '0/240';
            } else {
                return `${retry}/240`;
            }
        },
        extractFirstAndLastNameFromCounterpartyName(counterpartyName) {
            const splitName = counterpartyName.split(' ');
            let firstname = '';
            let lastname = '';
            if (splitName.length > 1) {
                firstname = splitName[0];
                let extractedLastName = '';
                for (let i = 1; i < splitName.length; i++) {
                    extractedLastName += splitName[i];
                }
                lastname = extractedLastName;
            }
            return { firstname, lastname, };
        },
        handleHistorySidebar() {
            this.$root.$emit(this.sidebarEvent, 'history');
        },
        handleWorkerSidebar() {
            try {
                if (this.$router.query === undefined) {
                    const route = this.$router.resolve({
                        name: '#/workers',
                        query: {
                            uid: this.transaction.uid,
                            companyId: this.transaction.companyId
                        },
                    });
                    window.open(route.href, '_blank');
                }
            } catch (e) {
                this.makeToast(false, e);
            }
        },
        getMenuOptions() {
            return [
                {
                    handle: () => this.$root.$emit(this.sidebarEvent, 'update-tx'),
                    title: 'Update',
                    if: (this.isAnyKindOfPayout() && this.isUpdatable(this.transaction.status)),
                },
                {
                    handle: () => this.$root.$emit(this.sidebarEvent, 'history'),
                    title: this.$t('admin.history.menu.title')
                }
            ];
        }
    },
};
</script>
